import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-boulder-colorado.png'
import image0 from "../../images/cities/scale-model-of-boulder-walking-tours-in-boulder-colorado.png"
import image1 from "../../images/cities/scale-model-of-information-in-boulder-colorado.png"
import image2 from "../../images/cities/scale-model-of-andrews-arboretum-in-boulder-colorado.png"
import image3 from "../../images/cities/scale-model-of-rabbit-hole-recreation-services---escape-rooms-in-boulder-colorado.png"
import image4 from "../../images/cities/scale-model-of-fiske-planetarium-in-boulder-colorado.png"
import image5 from "../../images/cities/scale-model-of-valmont-bike-park-in-boulder-colorado.png"
import image6 from "../../images/cities/scale-model-of-north-boulder-park-in-boulder-colorado.png"
import image7 from "../../images/cities/scale-model-of-tom-watson-park-in-boulder-colorado.png"
import image8 from "../../images/cities/scale-model-of-boulder-arts-&-crafts-gallery-in-boulder-colorado.png"
import image9 from "../../images/cities/scale-model-of-mork-and-mindy-house-in-boulder-colorado.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Boulder'
            state='Colorado'
            image={image}
            lat='40.027'
            lon='-105.2519'
            attractions={ [{"name": "Boulder Walking Tours", "vicinity": "1420 Alpine Ave, Boulder", "types": ["point_of_interest", "establishment"], "lat": 40.025305, "lng": -105.27870000000001}, {"name": "Information", "vicinity": "Downtown, Boulder", "types": ["point_of_interest", "establishment"], "lat": 40.0182587, "lng": -105.27859380000001}, {"name": "Andrews Arboretum", "vicinity": "1560 Broadway, Boulder", "types": ["park", "point_of_interest", "establishment"], "lat": 40.0123708, "lng": -105.27787969999997}, {"name": "Rabbit Hole Recreation Services - Escape Rooms", "vicinity": "1156 W Dillon Rd #1, Louisville", "types": ["point_of_interest", "establishment"], "lat": 39.9607272, "lng": -105.16668070000003}, {"name": "Fiske Planetarium", "vicinity": "2414 Regent Dr, Boulder", "types": ["point_of_interest", "establishment"], "lat": 40.0035992, "lng": -105.26344360000002}, {"name": "Valmont Bike Park", "vicinity": "3160 Airport Rd, Boulder", "types": ["park", "point_of_interest", "establishment"], "lat": 40.0318642, "lng": -105.2335263}, {"name": "North Boulder Park", "vicinity": "2848 9th St, Boulder", "types": ["park", "point_of_interest", "establishment"], "lat": 40.0276186, "lng": -105.2865099}, {"name": "Tom Watson Park", "vicinity": "6180 N 63rd St, Boulder", "types": ["park", "point_of_interest", "establishment"], "lat": 40.0859148, "lng": -105.20652719999998}, {"name": "Boulder Arts & Crafts Gallery", "vicinity": "1421 Pearl St, Boulder", "types": ["art_gallery", "jewelry_store", "store", "point_of_interest", "establishment"], "lat": 40.0187471, "lng": -105.276902}, {"name": "Mork and Mindy House", "vicinity": "0000973, Boulder", "types": ["point_of_interest", "establishment"], "lat": 40.0213442, "lng": -105.27500889999999}] }
            attractionImages={ {"Boulder Walking Tours":image0,"Information":image1,"Andrews Arboretum":image2,"Rabbit Hole Recreation Services - Escape Rooms":image3,"Fiske Planetarium":image4,"Valmont Bike Park":image5,"North Boulder Park":image6,"Tom Watson Park":image7,"Boulder Arts & Crafts Gallery":image8,"Mork and Mindy House":image9,} }
       />);
  }
}